
import Component from "vue-class-component";
import Vue from "vue";
import Modal from "@/components/Modal.vue";
import Avatar from "@/components/Avatar.vue";
import { State as StateStore } from "../store";
import { State, Mutation, Getter } from "vuex-class";
import { Prop } from "vue-property-decorator";
import * as Api from "@/types/graphql";
import { getSuspendedStatusCode, StatusCodes } from "@/utils/status";
import gql from "graphql-tag";

@Component({
  components: {
    Modal,
    Avatar,
  },
})
export default class DeleteCompanyServiceModal extends Vue {
  @Mutation showSuccess;
  @Mutation showError;
  @Prop()
  companyService: Api.CompanyService;

  loading = false;

  async confirm() {
    this.loading = true;

    const res = await this.$apollo.mutate({
      mutation: gql`
        mutation put($guid: String) {
          deleteCompanyService(company_service_guid: $guid)
        }
      `,
      variables: {
        guid: this.companyService.guid,
      },
    });
    if (res.data.deleteCompanyService) {
      this.showSuccess("Servizio eliminato");
      this.$emit("success");
      this.onClose();
    } else {
      this.showError("Errore durante eliminazione servizio utente");
    }
  }

  onClose() {
    this.$emit("close");
  }
}
