
import * as Api from "@/types/graphql";
import _ from "lodash";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Action, Mutation, State } from "vuex-class";
import { State as StateStore } from "../store";

@Component({
  components: {},
  watch: {
    selectedServicesSubState(val, prev) {
      this.selectedServicesSub = val;
    },
  },
})
export default class ServicesSubSelector extends Vue {
  @State((state: StateStore) => state.data.servicesSub)
  servicesSubState: Api.ServiceSub[];
  @State((state: StateStore) => state.data.selectedServicesSub)
  selectedServicesSubState: Api.ServiceSub[];

  @Mutation addSelectedServicesSub;
  @Mutation removeSelectedServiceSub;
  @Mutation setSelectedServicesSub;
  @Mutation resetSelectedServicesSub;
  @Action getServicesSub;

  @Prop({ default: "Seleziona sotto servizio" })
  label: string;
  @Prop({ default: false })
  multiple: boolean;
  @Prop()
  serviceGuid: string;

  options = [];
  selectedServicesSub = [];
  selected = [];

  async mounted() {
    if (this.serviceGuid != null && this.serviceGuid != "0") {
      await this.getServicesSub({ serviceGuid: this.serviceGuid });
    }
  }

  filter(item, queryText) {
    if (item.header || (queryText as string)?.length < 3) return false;

    return item.des
      .toString()
      .toLowerCase()
      .includes(queryText.toString().toLowerCase());
  }

  isSelected(service) {
    return _.findIndex(this.selectedServicesSubState, service) > -1;
  }

  onChange(services) {
    if (this.multiple == false) {
      console.table(services);
      console.table(this.selectedServicesSubState);
      const service = _.differenceBy(
        services,
        this.selectedServicesSubState,
        "guid"
      );
      this.setSelectedServicesSub(service);
      console.log(service);
    } else {
      this.setSelectedServicesSub(
        _.filter(services, (service) => {
          return service instanceof Object;
        })
      );
    }
  }
}
