
import { expirationData } from "@/utils/services";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import _ from "lodash";

@Component({})
export default class CompanyServiceExpirationSelector extends Vue {
  @Prop({ default: null })
  preSelectedValue: string;

  items = expirationData;
  selectedValue = null;

  @Watch("preSelectedValue")
  handlePreSelectedValue(val) {
    if (val != null) {
      this.selectedValue = _.find(this.items, { value: val });
    } else {
      this.selectedValue = null;
    }
  }

  onChange(expiration) {
    this.$emit("selected", expiration);
  }
}
