
import * as Api from "@/types/graphql";
import { Component, Vue } from "vue-property-decorator";
import Card from "@/components/Card.vue";
import Modal from "@/components/Modal.vue";
import LessonItem from "@/components/LessonItem.vue";
import { Action, Mutation, State } from "vuex-class";
import { State as StateStore } from "../store";
import * as _ from "lodash";
import { CourseAvailability } from "@/utils/courses";
import moment from "moment";
import { formatDateFromApi } from "@/plugins/moment";
import { checkUserRole, RoleCodes } from "@/utils/roles";
import CompanyServicesTableGeneral from "@/components/CompanyServicesTableGeneral.vue";

@Component({
  components: { Card, Modal, LessonItem, CompanyServicesTableGeneral },
  watch: {
    companyServices(val, prev) {
      this.handleServices(val);
    },
  },
})
export default class DeadlinesCalendar extends Vue {
  @State((state: StateStore) => state.auth.user) user: Api.User;
  @State((state: StateStore) => state.data.companyServices)
  companyServices: Api.CompanyService[];
  @Mutation showError;
  @Action getCompanyServices;
  @Action getCompany;

  weekDays = [1, 2, 3, 4, 5, 6, 0];
  value = "";
  type = "month";
  types = [
    { value: "day", label: "Giorno" },
    { value: "week", label: "Settimana" },
    { value: "month", label: "Mese" },
  ];

  loading = false;

  services = [];
  calendarServices = [];

  startDate = moment()
    .clone()
    .startOf("month")
    .format("YYYY-MM-DD");
  endDate = moment()
    .clone()
    .endOf("month")
    .format("YYYY-MM-DD");
  month = null;

  selectedLesson = null;

  async getServices(datesData) {
    this.startDate = datesData.start.date;
    this.endDate = datesData.end.date;
    this.month = datesData.start.month;
    try {
      await this.getCompanyServices({
        companyGuid: "",
        from: datesData.start.date,
        to: datesData.end.date,
      });
    } catch (error) {
      console.error(error);
    }
  }

  async handleServices(services) {
    this.services = services;
    this.calendarServices = [];
    services.forEach(async (service) => {
      const company = await this.getCompany(service.company_guid);
      service["company"] = company;
      this.calendarServices.push({
        guid: service.guid,
        name: company.ragsoc + " - " + service.service.des,
        start: service.due_date,
        color: "primary",
        timed: false,
      });
    });
  }

  formatDate(date) {
    return formatDateFromApi(date, "dddd DD MMMM YYYY");
  }

  formatMonth() {
    return moment(this.startDate).format("MMMM YYYY");
  }

  getEventColor(event) {
    let hash = 0;
    for (
      let i = 0;
      i < event.guid.length;
      hash = event.guid.charCodeAt(i++) + ((hash << 5) - hash)
    );
    const color = Math.floor(
      Math.abs(((Math.sin(hash) * 10000) % 1) * 16777216)
    ).toString(16);
    return "#" + Array(6 - color.length + 1).join("0") + color;
  }

  openDay(data) {
    this.type = "day";
    this.value = data;
    this.startDate = data.date;
    this.endDate = data.date;
  }
}
