
import Avatar from "@/components/Avatar.vue";
import Btn from "@/components/Btn.vue";
import Modal from "@/components/Modal.vue";
import gql from "graphql-tag";
import _ from "lodash";
import validator from "validator";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Mutation } from "vuex-class";

@Component({
  components: {
    Modal,
    Btn,
    Avatar,
  },
})
export default class AddUserToCognitoModal extends Vue {
  @Mutation showSuccess;
  @Mutation showError;

  isFormValid = false;
  loading = false;
  email = null;

  emailRules = [
    (v) => !!v || "Inserire la mail",
    (v) => !!validator.isEmail(v ? v : "") || "Email non valida",
  ];

  @Prop()
  user;

  capitalize(text) {
    return _.capitalize(text);
  }

  async onConfirm() {
    this.loading = true;
    if (await this.checkAndSetEmail()) {
      const res = await this.$apollo.mutate({
        mutation: gql`
          mutation insertUserIntoCognito($guid: ID!) {
            insertUserIntoCognito(guid: $guid)
          }
        `,
        variables: {
          guid: this.user.guid,
        },
      });
      if (res.data.insertUserIntoCognito) {
        this.showSuccess("Utenza attivata correttamente");
        this.$emit("update");
        this.onClose();
      } else {
        this.showError("Errore durante attivazione utenza");
      }
    }
    this.loading = false;
  }

  async checkAndSetEmail() {
    if (this.user.email == "") {
      if (
        (this.$refs.userForm as Vue & {
          validate: () => boolean;
        }).validate()
      ) {
        return await this.$apollo.mutate({
          mutation: gql`
            mutation put($guid: ID!, $email: String) {
              updateUser(guid: $guid, email: $email)
            }
          `,
          variables: {
            guid: this.user.guid,
            email: this.email,
          },
        });
      } else return false;
    } else return true;
  }

  onClose() {
    this.$emit("close");
  }
}
