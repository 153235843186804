
import Btn from "@/components/Btn.vue";
import Card from "@/components/Card.vue";
import UsersSelector from "@/components/UsersSelector.vue";
import SelectedUsers from "@/components/SelectedUsers.vue";
import SelectedServices from "@/components/SelectedServices.vue";
import ServicesSelector from "@/components/ServicesSelector.vue";
import ServicesSubSelector from "@/components/ServicesSubSelector.vue";
import CompanyServiceExpirationSelector from "@/components/CompanyServiceExpirationSelector.vue";
import Modal from "@/components/Modal.vue";
import * as Api from "@/types/graphql";
import { Component, Vue, Prop } from "vue-property-decorator";
import { Action, Getter, Mutation, State } from "vuex-class";
import { State as StateStore } from "../store";
import _ from "lodash";
import { ServiceStatus } from "@/utils/status";
import DatePicker from "@/components/DatePicker.vue";
import { RoleCodes } from "@/utils/roles";
import { formatForApi, getToday } from "@/plugins/moment";
import gql from "graphql-tag";
import moment, { Duration } from "moment";
import { CUSTOM_EXPIRATION } from "@/utils/services";

@Component({
  components: {
    Btn,
    Card,
    ServicesSelector,
    ServicesSubSelector,
    CompanyServiceExpirationSelector,
    SelectedServices,
    UsersSelector,
    SelectedUsers,
    DatePicker,
    Modal,
  },
  computed: {},
  watch: {
    companyService(companyService, prev) {
      console.log(companyService);
      if (companyService != null) {
        this.setSelectedServices([companyService.service]);
        this.setSelectedServicesSub([companyService.servicesub]);
        this.setSelectedUsers(companyService.employees);
        this.setSelectedUsers2([companyService.referent]);
        this.booking = companyService.booking;
        this.notes = companyService.notes;
        this.lastDate = companyService.latest_service_date;
        this.expiryDate = companyService.due_date;
        this.duration = companyService.duration;
      }
    },
  },
})
export default class EditCompanyServiceModal extends Vue {
  @State((state: StateStore) => state.data.selectedServices)
  selectedServices: Api.Service[];
  @State((state: StateStore) => state.data.selectedServicesSub)
  selectedServicesSub: Api.Service[];
  @State((state: StateStore) => state.data.selectedUsers)
  selectedUsers: Api.User[];
  @State((state: StateStore) => state.data.selectedUsers2)
  selectedUsers2: Api.User[];
  @Action updateCompanyServices;
  @Action sendEmailToCompanies;
  @Getter getRole: (code: string) => Api.Role;
  @Mutation setSelectedServices;
  @Mutation resetSelectedServices;
  @Mutation resetSelectedUsers;
  @Mutation resetSelectedUsers2;
  @Mutation setSelectedUsers;
  @Mutation setSelectedUsers2;
  @Mutation showError;
  @Mutation showSuccess;
  @Mutation removeSelectedUser;
  @Mutation removeSelectedUser2;
  @Mutation resetSelectedServicesSub;
  @Mutation setSelectedServicesSub;

  loading = false;

  @Prop()
  companyService;

  expiryDate = null;
  lastDate = null;
  duration = null;
  notes = null;
  booking = false;

  getAdminFilter() {
    return [
      this.getRole(RoleCodes.ADMIN1).guid,
      this.getRole(RoleCodes.ADMIN2).guid,
    ];
  }

  getUserFilter() {
    return [this.getRole(RoleCodes.USER).guid];
  }

  expirationChanged(expiration) {
    if (this.lastDate != null) {
      const duration: Duration = expiration.duration as Duration;
      const newExpiryDate = moment(this.lastDate, "YYYY-MM-DD").add(duration);
      this.expiryDate = formatForApi(newExpiryDate);
      this.duration = expiration.value;
    }
  }

  expireDateChanged(date) {
    this.expiryDate = date;
    this.duration = CUSTOM_EXPIRATION;
  }

  async onConfirm() {
    this.loading = true;
    const service = {};
    service["company_guid"] = this.companyService.company_guid;
    service["service_guid"] = this.selectedServices[0].guid;
    service["service_type_guid"] = this.selectedServices[0].servicetype.guid;
    service["status"] = this.companyService.status;
    service["service_sub_guid"] = this.selectedServicesSub[0]
      ? this.selectedServicesSub[0].guid
      : "";
    service["employees_guid"] = _.map(this.selectedUsers, "guid");
    service["referent_guid"] = this.selectedUsers2[0].guid;
    service["notes"] = this.notes;
    service["due_date"] = this.expiryDate;
    service["latest_service_date"] = this.lastDate;
    service["duration"] = this.duration;
    service["booking"] = this.booking ? 1 : 0;

    const res = await this.$apollo.mutate({
      mutation: gql`
        mutation service_update(
          $guid: String
          $service: [CompanyServiceInput]
        ) {
          updateCompanyService(guid: $guid, service: $service)
        }
      `,
      variables: {
        guid: this.companyService.guid,
        service: [service],
      },
    });

    if (res.data.updateCompanyService) {
      this.onClose();
      this.showSuccess("Servizio correttamente modificato");
      this.$emit("success");
    } else {
      this.showError("Errore durante la modifica del servizio");
    }
    this.loading = false;
  }

  getMinDate() {
    return getToday();
  }

  getFormattedDate(date) {
    if (!date) return null;

    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year}`;
  }

  disableConfirm() {
    return (
      this.selectedServices.length == 0 ||
      this.selectedServicesSub.length == 0 ||
      this.selectedUsers2.length == 0 ||
      this.lastDate == null ||
      this.expiryDate == null
    );
  }

  onClose() {
    this.resetSelectedServices();
    this.resetSelectedServicesSub();
    this.resetSelectedUsers();
    this.resetSelectedUsers2();
    this.$emit("close");
  }

  getSelectedServiceGuid() {
    if (this.selectedServices[0] == null) {
      return "0";
    } else {
      return this.selectedServices[0].guid;
    }
  }
}
