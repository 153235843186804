
import Card from "@/components/Card.vue";
import CompanyServicesTableGeneral from "@/components/CompanyServicesTableGeneral.vue";
import DeadlinesCalendar from "@/components/DeadlinesCalendar.vue";
import Modal from "@/components/Modal.vue";
import * as Api from "@/types/graphql";
import moment from "moment";
import { Component, Vue } from "vue-property-decorator";
import { Action, Mutation, State } from "vuex-class";
import { State as StateStore } from "../store";

@Component({
  components: { Card, Modal, DeadlinesCalendar, CompanyServicesTableGeneral },
  computed: {
    tab: {
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } });
      },
      get() {
        return this.$route.query.tab;
      },
    },
  },
})
export default class DeadlinesView extends Vue {
  @State((state: StateStore) => state.auth.user) user: Api.User;
  @State((state: StateStore) => state.data.companyServices)
  companyServices: Api.CompanyService[];
  @Mutation showError;
  @Mutation setCompanyServices;
  @Action getCompanyServices;
  @Action getCompany;

  tabKeys = ["elenco", "calendario"];

  componentKey = 1;

  onChangeTab() {
    this.setCompanyServices([]);
    this.componentKey++;
  }
}
