
import Avatar from "@/components/Avatar.vue";
import Btn from "@/components/Btn.vue";
import Card from "@/components/Card.vue";
import Modal from "@/components/Modal.vue";
import DatePicker from "@/components/DatePicker.vue";
import HandleCompanyRoleModal from "@/components/HandleCompanyRoleModal.vue";
import AddUserToCognitoModal from "@/components/AddUserToCognitoModal.vue";
import { checkUserRole, RoleCodes } from "@/utils/roles";
import { getReferentName } from "@/utils/user";
import gql from "graphql-tag";
import _ from "lodash";
import Vue from "vue";
import Component from "vue-class-component";
import { Mutation, State, Getter, Action } from "vuex-class";
import { State as StateStore } from "../store";
import * as Api from "@/types/graphql";
import ServicesSelector from "@/components/ServicesSelector.vue";
import ServicesSubSelector from "@/components/ServicesSubSelector.vue";
import SelectedServices from "@/components/SelectedServices.vue";
import { formatDateFromApi } from "@/plugins/moment";
import moment from "moment";
import { env, EnvVars } from "@/utils/env";
import { Auth } from "aws-amplify";
import DeleteCompanyServiceModal from "@/components/DeleteCompanyServiceModal.vue";
import EditCompanyServiceModal from "@/components/EditCompanyServiceModal.vue";
import { getDurationLabelFromValue } from "@/utils/services";

@Component({
  components: {
    Card,
    Avatar,
    Btn,
    Modal,
    HandleCompanyRoleModal,
    AddUserToCognitoModal,
    DeleteCompanyServiceModal,
    EditCompanyServiceModal,
    DatePicker,
    ServicesSubSelector,
    ServicesSelector,
    SelectedServices,
  },
  watch: {
    companyServices(val, prev) {
      this.handleServices();
    },
    selectedServices(val) {
      if (!this.showEditCompanyServiceModal) {
        this.resetSelectedServicesSub();
      }
    },
  },
})
export default class CompanyServicesTableGeneral extends Vue {
  @State((state: StateStore) => state.auth.user) user: Api.User;
  @State((state: StateStore) => state.data.companyServices)
  companyServices: Api.CompanyService[];
  @State((state: StateStore) => state.data.selectedServices)
  selectedServices: Api.Service[];
  @State((state: StateStore) => state.data.selectedServicesSub)
  selectedServicesSub: Api.ServiceSub[];
  @Mutation showSuccess;
  @Mutation showError;
  @Action getCompanyServices;
  @Action getCompany;
  @Mutation resetSelectedServicesSub;
  @Mutation resetSelectedServices;

  showDeleteCompanyServiceModal = false;
  deletingCompanyService = null;
  showEditCompanyServiceModal = false;
  editingCompanyService = null;

  // startDate = moment()
  //   .clone()
  //   .format("YYYY-MM-DD");
  // endDate = moment()
  //   .clone()
  //   .add(3, "months")
  //   .format("YYYY-MM-DD");
  startDate = null;
  endDate = null;
  services = [];
  loading = false;
  downloadLoading = false;

  mounted() {
    this.resetSelectedServicesSub();
    this.resetSelectedServices();
    this.getServices();
  }

  async getServices() {
    this.loading = true;
    this.services = [];
    try {
      await this.getCompanyServices({
        companyGuid: "",
        serviceGuid: this.selectedServices[0]
          ? this.selectedServices[0].guid
          : "",
        serviceSubGuid: this.selectedServicesSub[0]
          ? this.selectedServicesSub[0].guid
          : "",
        from: this.startDate,
        to: this.endDate,
      });
    } catch (error) {
      console.error(error);
      this.loading = false;
    }
  }

  async handleServices() {
    this.loading = true;
    this.services = [];
    this.companyServices.forEach(async (companyService) => {
      const service = { ...companyService, company: null };
      service.company = await this.getCompany(service.company_guid);
      this.services.push(service);
    });
    this.loading = false;
  }

  headers = [
    {
      text: "Scadenza",
      align: "start",
      sortable: true,
      value: "due_date",
    },
    {
      text: "Azienda",
      align: "start",
      sortable: false,
      value: "company",
    },
    {
      text: "Servizio",
      align: "start",
      sortable: false,
      value: "service",
      width: "30%",
    },
    {
      text: "Dipendenti",
      align: "start",
      sortable: false,
      value: "subservice",
    },
    {
      text: "Referente CNA",
      align: "start",
      sortable: false,
      value: "referent",
    },

    { text: "Azioni", value: "actions", sortable: false, filterable: false },
  ];
  selected = [];

  capitalize(text) {
    return _.capitalize(text);
  }

  getModalKey() {
    return Math.random();
  }

  isAdmin() {
    return checkUserRole(this.user, [RoleCodes.ADMIN1, RoleCodes.ADMIN2]);
  }

  formatDate(date) {
    return formatDateFromApi(date, "DD/MM/YYYY");
  }

  getCompanyName(companyService) {
    if (companyService.company != null) {
      return companyService.company.ragsoc;
    } else return "";
  }

  getSelectedServiceGuid() {
    if (this.selectedServices[0] == null) {
      return null;
    } else {
      return this.selectedServices[0].guid;
    }
  }

  async exportFile() {
    const token = await (await Auth.currentSession())
      .getIdToken()
      .getJwtToken();

    this.downloadLoading = true;
    fetch(
      env()[EnvVars.VUE_APP_BE] +
        "/company/service/listbyuseranddate/" +
        this.startDate +
        "/" +
        this.endDate,
      {
        method: "GET",
        headers: {
          "x-access-token": token,
        },
      }
    )
      .then((res) => {
        return res.blob();
      })
      .then((data) => {
        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(data);
        a.download = "Export";
        a.click();
      })
      .finally(() => {
        this.downloadLoading = false;
      });
  }

  getDurationLabelFromValue(value) {
    return getDurationLabelFromValue(value);
  }
}
