
import Btn from "@/components/Btn.vue";
import Avatar from "@/components/Avatar.vue";
import Modal from "@/components/Modal.vue";
import gql from "graphql-tag";
import _ from "lodash";
import Vue from "vue";
import Component from "vue-class-component";
import * as Api from "@/types/graphql";
import { Prop } from "vue-property-decorator";
import { Mutation, State, Getter } from "vuex-class";
import { State as StateStore } from "../store";
import validator from "validator";
import { getToday } from "@/plugins/moment";
import UsersSelector from "@/components/UsersSelector.vue";
import { RoleCodes } from "@/utils/roles";
import GenderSelector from "@/components/GenderSelector.vue";
import DatePicker from "@/components/DatePicker.vue";
import { StatusCodes } from "@/utils/status";

enum ModalStatus {
  SearchTaxCode = "SearchTaxCode",
  UserExists = "UserExists",
  CreateUser = "CreateUser",
}

@Component({
  components: {
    Modal,
    Btn,
    UsersSelector,
    GenderSelector,
    DatePicker,
    Avatar,
  },
})
export default class HandleCompanyRoleModal extends Vue {
  @Mutation showSuccess;
  @Mutation showError;
  @Getter getRole: (code: string) => Api.Role;
  @Getter getStatus: (code: string) => Api.UserStatus;

  isFormValid = false;
  loading = false;

  @Prop()
  company;

  @Prop()
  user;

  selectedStatus = null;
  userstatus = [];
  companyRoleRules = [(v) => !!v || "Inserire il ruolo in azienda"];

  mounted() {
    this.userstatus.push(this.getStatus(StatusCodes.USER_DIP_AZ));
    this.userstatus.push(this.getStatus(StatusCodes.USER_REF_AZ));
    this.userstatus.push(this.getStatus(StatusCodes.USER_FIRED));
    if (this.user != null) {
      this.selectedStatus = this.user.status.guid;
    }
  }

  roleIsEqual() {
    if (this.user == null) {
      return true;
    } else {
      return this.selectedStatus == this.user.status.guid;
    }
  }

  async onConfirm() {
    console.log("onConfirm", this.selectedStatus);
    this.loading = true;
    if (this.selectedStatus == this.getStatus(StatusCodes.USER_REF_AZ).guid) {
      await this.$apollo.mutate({
        mutation: require("@/graphql/deleteEmployee.graphql"),
        variables: {
          userGuid: this.user.guid,
          companyGuid: this.company.guid,
        },
      });
      await this.$apollo.mutate({
        mutation: gql`
          mutation put($guid: ID!, $referents: [UserInput]) {
            updateCompany(guid: $guid, referents: $referents)
          }
        `,
        variables: {
          guid: this.company.guid,
          referents: [{ guid: this.user.guid }],
        },
      });
    } else {
      if (this.user.status.guid == this.getStatus(StatusCodes.USER_REF_AZ).guid) {
        await this.$apollo.mutate({
          mutation: require("@/graphql/deleteReferent.graphql"),
          variables: {
            userGuid: this.user.guid,
            companyGuid: this.company.guid,
          },
        });
      }
      await this.$apollo.mutate({
        mutation: gql`
          mutation put($guid: ID!, $employees: [UserInput]) {
            updateCompany(guid: $guid, employees: $employees)
          }
        `,
        variables: {
          guid: this.company.guid,
          employees: [{ guid: this.user.guid }],
        },
      });
    }
    const res = await this.$apollo.mutate({
      mutation: require("@/graphql/setUserStatus.graphql"),
      variables: {
        guid: this.user.guid,
        role: this.getRole(RoleCodes.USER).guid,
        status: this.selectedStatus,
      },
    });
    if (res.data.changeStatusUser) {
      this.loading = false;
      this.showSuccess("Utente aggiornato correttamente");
      this.$emit("update");
      this.onClose();
    } else {
      this.showError("Errore durante aggiornamento utente");
    }
    // eliminare da employees e aggiungere a referents - e viceversa
  }

  onClose() {
    this.$emit("close");
  }
}
